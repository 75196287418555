import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
// import ReactGA from 'react-ga'
import store from './store'
import App from 'components/App'
import ErrorBoundary from 'components/utils/ErrorBoundary'
// import { logout } from 'actions/CurrentUser'
import { displayToast } from 'actions/Dashboard'
// import reportWebVitals from './reportWebVitals'
import './index.scss'

// if (process.env.REACT_APP_GA_TRACKING_ID) {
//   ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
// }

const history = createBrowserHistory()

axios.defaults.baseURL = process.env.REACT_APP_AUTH_URL
axios.defaults.withCredentials = true
axios.interceptors.response.use(
  (resp) => resp,
  (error) => {
    if (error.response.status === 401) {
      // store.dispatch(
      //   displayToast({
      //     message:
      //       'There was a problem accessing your data. You must be signed in to access this part of the application. Your session may have expired.',
      //     title: 'Error: Unauthorized',
      //     infoType: 'danger',
      //   }),
      // )
      history.push('/welcome')
    }
    return Promise.reject(error)
  },
)

if (process.env.NODE_ENV !== 'development') {
  const buildMetaEl = document.querySelector("meta[name='build-version']")
  const buildVersion = buildMetaEl?.getAttribute('content') || 'unknown'
  const sentryOptions: Sentry.BrowserOptions = {
    dsn: 'https://f5becdf21c884c50ba33ebf4ce87f9a9@sentry2.riskrecon.com/3',
    release: buildVersion,
    environment: process.env.REACT_APP_ENVIRONMENT,
    tunnel: `${process.env.REACT_APP_AUTH_URL}/sentry_tunnel`,
    transportOptions: {
      dsn: 'https://f5becdf21c884c50ba33ebf4ce87f9a9@sentry2.riskrecon.com/3',
      fetchParameters: {
        credentials: 'include',
      },
    },
  }
  Sentry.init(sentryOptions)
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <React.StrictMode>
        <Sentry.ErrorBoundary fallback={ErrorBoundary}>
          <App />
        </Sentry.ErrorBoundary>
      </React.StrictMode>
    </Router>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
