import { AnyAction } from 'redux'
import { FETCH_CURRENT_USER_INFO_SUCCESS } from '../actions/CurrentUser'

export interface CurrentUserTypes {
  customer_name: string
  customer_type: string
  // eula_accepted_on: string | null
  product_tour_at: string | null
  single_toe_analysis_id: string
  user_id: number | null
  customer_region: string
}

const initialState: CurrentUserTypes = {
  customer_name: '',
  customer_type: '',
  // eula_accepted_on: null,
  product_tour_at: null,
  single_toe_analysis_id: '',
  user_id: null,
  customer_region: '',
}

function CurrentUser(
  state: CurrentUserTypes = initialState,
  action: AnyAction,
): CurrentUserTypes {
  switch (action.type) {
    case 'RESET_APP_STATE':
      return initialState
    case FETCH_CURRENT_USER_INFO_SUCCESS:
      return {
        ...state,
        customer_name: action.currentUser.customer_name,
        customer_type: action.currentUser.customer_type,
        // eula_accepted_on: action.currentUser.eula_accepted_on,
        product_tour_at: action.currentUser.product_tour_at,
        single_toe_analysis_id: action.currentUser.single_toe_analysis_id,
        user_id: action.currentUser.user_id,
        customer_region: action.currentUser.customer_region || '',
      }
    // case 'SET_EULA_ACCEPTED':
    //   return {
    //     ...state,
    //     eula_accepted_on: action.eula_accepted_on,
    //   }
    default:
      return state
  }
}

export default CurrentUser
