import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/common'

import LanguageSelector from '../LanguageSelector'

import MCRLogo from 'mcr_logo.svg'

import './TopNav.scss'

const TopNavPublic = () => {
  const { t } = useTranslation('pub', {
    keyPrefix: 'PublicRoutes.topNav',
  })
  return (
    <nav role="navigation" id="top-nav">
      <div className="nav-body">
        <div className="nav-left">
          <Link to="/" className="navbar-logo">
            <img src={MCRLogo} className="logo-svg" alt="Logo" />
          </Link>
        </div>
        <div className="nav-right">
          <div className="nav-items">
            <div className="public-button-group">
              <Button className="login" color="blue outline">
                <a href={process.env.REACT_APP_AUTH_URL}>{t('logIn')}</a>
              </Button>
              <Button className="signup" color="blue">
                <Link to="/sign-up">{t('signUp')}</Link>
              </Button>
            </div>
            <LanguageSelector publicPages />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default TopNavPublic
